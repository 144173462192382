import clsx from 'clsx'
import { styled } from '@mui/material/styles'

type ReactTdProps = React.DetailedHTMLProps<React.TdHTMLAttributes<HTMLTableCellElement>, HTMLTableCellElement>

const Td = styled('td')(({ theme }) => ({
  padding: '1rem 0.75rem',
  overflow: 'hidden',
  color: '#525252',
  textOverflow: 'ellipsis',
  '&.expand': {
    width: '100%',
  },
  '&.right': {
    textAlign: 'right',
  },
  '&.center': {
    textAlign: 'center',
  },
  '&:last-of-type': {
    paddingRight: '1.5rem',
  },
}))

export type CellProps = {
  children: React.ReactNode
  preventClick?: boolean
  expand?: boolean
  maxWidth?: number
  right?: boolean
  center?: boolean
} & ReactTdProps

const Cell: React.FC<CellProps> = ({
  children,
  preventClick = false,
  expand = false,
  maxWidth,
  right,
  center,
  className,
  ...tdProps
}) => {
  const onClick = (event: React.MouseEvent<HTMLTableCellElement>) => {
    event.preventDefault()
    event.stopPropagation()
  }
  return (
    <Td
      style={{ maxWidth }}
      className={clsx(
        expand && 'expand',
        right && 'right',
        center && 'center',
        className,
      )}
      onClick={preventClick ? onClick : undefined}
      {...tdProps}
    >
      { children }
    </Td>
  )
}

export const LargeScreenCell = styled(Cell)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}))

export default Cell
